import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import PageTemplate from 'templates/pageTemplate'
import { ButtonSmallExternal } from 'components/atoms'
import pamphletPDF from 'assets/document/fit_net_study_20240221.pdf'
import globalTitle from 'utils/globalTitle'

interface PamphletPageProps {
  location: Location
}

const PamphletPage = ({ location }: PamphletPageProps): JSX.Element => (
  <Layout pageType="page" ctaType="type1">
    <Seo title={`電子パンフレット${globalTitle(location.pathname)}`} path={location.pathname} isNoIndex={true} />
    <PageTemplate
      headingText="電子パンフレット"
      contentElement={
        <>
          <div className="u-text-center">
            <p>資料請求ありがとうございました。</p>
            <p>
              <span className="u-words">電子パンフレットは下記リンクから</span>
              <span className="u-words">ダウンロードしてください。</span>
            </p>
            <div className="u-pd-t-30px">
              <ButtonSmallExternal id="download_pamphlet" href={pamphletPDF} value="ダウンロードする" isArrow={false} />
            </div>
          </div>
        </>
      }
    />
  </Layout>
)

export default PamphletPage
